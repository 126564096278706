<template>
  <base-layout>
    <div class="row text-center">
      <div class="col-lg-3 col-md-2"></div>
      <div class="col-lg-6 col-md-8">
        <div class="widget widget-hover-effect2">
          <form class="form-horizontal" style="padding: 20px;" @submit.prevent="buyInsurance">
            <div class="block-title themed-background">
              <h1>Submit form insurance</h1>
            </div>
            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }" style="margin-top: 10px">
                <div class="font-medium text-base col-span-12 text-white">
                  Form main balance
                </div>
                <div class="col-xs-12">
                  <input
                    v-model="Insurance.balance"
                    type="passport"
                    name="passport"
                    class="form-control input-lg"
                    readonly
                    placeholder="Enter passport"
                  />
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }" style="margin-top: 10px">
                <div class="font-medium text-base col-span-12 text-white">
                  Amount insurance
                </div>
                <div class="col-xs-12">
                  <input
                    v-on:keyup="feeInsurance"
                    v-model="insurances.amount"
                    type="number"
                    required
                    name="amount"
                    class="form-control input-lg"
                    placeholder="Enter amount insurance"
                  />
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }" style="margin-top: 10px">
                <div class="font-medium text-base col-span-12 text-white">
                  Time limit
                </div>
                <div class="col-xs-12">
                  <select class="form-control" v-model="insurances.days" @change="onChangeFee()">
                    <option value="" selected>Select time</option>
                    <option v-for="item in Insurance.list_limit" :key="item.id" :value="item.Date"
                      >{{ item.Date }} Days ( {{ item.Fee * 100 }}% Fee )</option
                    >
                  </select>
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }" style="margin-top: 10px">
                <div class="font-medium text-base col-span-12 text-white">
                  Amount fee (<span id="total_fee">0</span>%)
                </div>
                <div class="col-xs-12">
                  <input
                    v-model="fee"
                    type="number"
                    step="any"
                    name="fee"
                    readonly
                    class="form-control input-lg"
                    placeholder="0"
                  />
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }" style="margin-top: 10px">
                <div class="font-medium text-base col-span-12 text-white">
                  Game type
                </div>
                <div class="col-xs-12">
                  <select class="form-control" v-model="insurances.game">
                    <option value="" selected>Select game</option>
                    <option
                      v-for="item in Insurance.list_game"
                      :key="item.Promotion_Game_ID"
                      :value="item.Promotion_Game_Name"
                      >{{ item.Promotion_Game_Name }}</option
                    >
                  </select>
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }" style="margin-top: 10px">
                <div class="font-medium text-base col-span-12 text-white">
                  Time Zoom
                </div>
                <div class="col-xs-12">
                  <select class="form-control" v-model="insurances.time">
                    <option value="" selected>Select time zoom</option>
                    <option
                      v-for="item in Insurance.list_zoom"
                      :key="item.id + 10"
                      :value="item.time"
                      >{{ item.time }}</option
                    >
                  </select>
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }" style="margin-top: 10px">
                <div class="font-medium text-base col-span-12 text-white">
                  Countries
                </div>
                <div class="col-xs-12">
                  <select class="form-control" v-model="insurances.countries">
                    <option value="" selected>Select countries</option>
                    <option
                      v-for="item in Insurance.list_countries"
                      :key="item.ID + 10"
                      :value="item.Countries_id"
                      >{{ item.Countries_id }}</option
                    >
                  </select>
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>

            <div class="form-group">
              <div class="col-xs-12 text-center">
                <button type="submit" style="min-width:125px" class="btn btn-sm btn-success">
                  <i class="fa fa-send"></i> Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-12">
        <div class="widget widget-hover-effect2">
          <div class="block-title themed-background" style="margin: 0">
            <h1>History</h1>
          </div>
          <div class="table-responsive" style="margin: 0">
            <table class="table table-vcenter table-bordered table-hover table-condensed">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Game</th>
                  <th>Countries</th>
                  <th>Time Limit</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <template>
                  <tr v-for="item in Insurance.history" :key="item.id" style="text-align: left;">
                    <td>{{ item.id }}</td>
                    <td>{{ item.amount | formatNumber }}</td>
                    <td>{{ item.game }}</td>
                    <td>{{ item.countries }}</td>
                    <td>{{ item.days }} days</td>
                    <td>{{ item.created_time }}</td>
                    <td>{{ item.expired_time }}</td>
                    <td>
                      <span
                        :class="
                          item.status == 0
                            ? 'btn btn-sm btn-success'
                            : item.status == 1
                            ? 'btn btn-sm btn-warning'
                            : 'btn btn-sm btn-danger'
                        "
                        >{{
                          item.status == 0 ? "Available" : item.status == 1 ? "Expired" : "Cancel"
                        }}</span
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "./layouts/Base";
import { mapGetters } from "vuex";
import { changePassword } from "@/services/member";
import { async } from "vue-phone-number-input/dist/vue-phone-number-input.common";
export default {
  components: { BaseLayout },
  data: () => ({
    feeArray: [],
    amountFee: 0,
    fee: 0,
    insurances: {
      amount: "",
      game: "",
      time: "",
      days: "",
      countries: "",
      token: ""
    }
  }),
  computed: {
    ...mapGetters({
      Insurance: "insurance/Insurance"
    })
  },
  mounted() {},
  created() {
    this.$store.dispatch("insurance/req_getInsurance");
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    buyInsurance: async function() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("deposit");
      if (this.insurances.amount <= 0) {
        this.$toast.error("Amount > 0 ", "Insurance Failed!");
      } else if (this.fee > this.Insurance.balance) {
        this.$toast.error("Your Balance Is Not Enough ", "Insurance Failed!");
      } else if (!token) {
        this.$toast.error("Please try again", "Insurance Failed!");
      } else {
        this.insurances.token = token;
        this.$store.dispatch("insurance/req_postInsurance", this.insurances);
      }
    },
    onChangeFee: function(event) {
      this.feeArray = this.Insurance.fee;
      this.amountFee = this.feeArray[this.insurances.days];
      this.fee = this.insurances.amount * this.amountFee;
      $("#total_fee").html(this.amountFee * 100);
    },
    feeInsurance: function(event) {
      this.feeArray = this.Insurance.fee;
      this.amountFee = this.feeArray[this.insurances.days];
      this.fee = this.insurances.amount * this.amountFee;
    }
  },
  filters: {
    formatNumber(value) {
      if (!value) return 0;
      return new Intl.NumberFormat().format(value);
    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center !important;
}
.btn-group > .btn {
  float: none;
}
.table thead > tr > th {
  font-size: 16px;
  font-weight: 400;
}
.justify-content-center {
  justify-content: center !important;
}
.font-medium,
.text-theme-11 {
  text-align: left;
  color: #000;
  padding: 0 15px;
  margin-bottom: 0px;
}
.text-theme-11 {
  color: yellow;
}
.upload_images {
  width: calc(100% - 30px);
  height: 160px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background: #daf2fdb3 !important;
  border-radius: 10px;
  margin: 0 15px;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.widget {
  background-color: rgb(255 255 255 / 1);
}
.show_images {
  width: calc(100% - 30px);
  height: 200px;
  position: relative;
  padding: 20px;
  margin: 0 15px;
  text-align: center;
  background: #daf2fdb3 !important;
}

.show_images img {
  height: 100%;
  width: auto;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images button {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  background: gold;
  border: none;
  right: 0;
  padding: 5px 15px;
  bottom: 0;
  margin: auto;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images:hover {
  background: #056996b3 !important;
}

.show_images:hover img {
  opacity: 0.5;
}

.show_images:hover button {
  opacity: 1;
}

.upload_file_1 {
  height: 0;
  overflow: hidden;
  width: 0;
}

.upload_file_1 + label {
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-size: inherit;
  font-weight: 500;
  outline: none;
  padding: 10px 49px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  width: max-content;
}

.upload_file_1 + label.btn-2 {
  background-color: #f78b00;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 4px 10px -4px rgb(0 0 0 / 0.7);
}
.upload_file_1 + label.btn-2::before {
  color: #fff;
  content: "\f382";
  font-family: "Font Awesome 5 Pro";
  font-size: 100%;
  height: 100%;
  right: 130%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.upload_file_1 + label.btn-2:hover {
  background-color: #fbc500;
}
.upload_file_1 + label.btn-2:hover::before {
  right: 75%;
}
.icon {
  width: 80px;
  height: 80px;
  margin: auto;
  background: #c8973b;
  line-height: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #fff;
}
.mr-5 {
  margin-right: 5px !important;
}
.vm--modal {
  overflow-y: auto;
}
.profile h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.bg-theme-11 {
  margin: 0;
  height: 47px;
  display: block;
  line-height: 0;
  padding: 10px;
  background: #3ab32a;
  color: #fff;
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
.col-4 {
  width: 33.33333333%;
  float: left;
}
.col-8 {
  width: 66.6666667%;
  float: left;
}
</style>

import baseAPI from './base';

const resource = 'member';
const list = async(page, userID, userEmail, createdAt) => {
    const response = await baseAPI().post(`${resource}/member-list`, {
        user_id: userID,
        user_email: userEmail,
        created_at: createdAt,
        page,
    });
    return response.data.data;
};

const add = async(password, sponsor, email, token) => {
    const response = await baseAPI().post(`${resource}/add-member`, {
        password,
        sponsor,
        email,
        token,
    });
    return response.data;
};

const changePassword = async(passwordCurrent, password, passwordConfirm, token) => {
    const response = await baseAPI().post(`profile/change-password`, {
        passwordCurrent,
        password,
        passwordConfirm,
        token,
    });
    return response.data;
};

export default {
    list,
    add,
    changePassword,
};
